<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      title="Remove the product"
      @accept="removeItemFromCart"
      :active.sync="removeConfirm"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("Design_Message_1") }}</span>
      </div>
    </vs-prompt>

    <div class="grid grid-cols-12">
      <!-- Left Side -->
      <div class="col-span-9">
        <!-- Body -->
        <div class="mt-4">
          <div v-for="product in getProducts" :key="product.id + product.kg">
            <div class="grid grid-cols-12 gap-4 productRow mt-2">
              <div class="col-span-2">
                <img class="cartImage" :src="product.image" alt="" />
              </div>

              <div class="col-span-8">
                <div class="productTitle">
                  {{ product.title }}
                </div>
                <div>
                  {{ product.description }}
                </div>
                <div
                  class="productKg"
                  :style="
                    product.kg == 1
                      ? 'background-color: #32AD35'
                      : product.kg == 0.5
                      ? 'background-color: #FA8B2F'
                      : 'background-color: #8161FA'
                  "
                >
                  {{ product.kg }} - Kg
                </div>

                <div class="productPrice mt-4">{{ product.price }}$</div>
              </div>

              <div class="col-span-1 text-center" style="align-self: center">
                <vs-button
                  class="p-0"
                  radius
                  color="#FFFFFF"
                  @click="
                    selectedProduct = product;
                    addQuantityProductCart();
                  "
                >
                  <vs-icon
                    icon="add_circle"
                    color="dark"
                    size="small"
                  ></vs-icon>
                </vs-button>

                <vs-input-number
                  class="productQuantity"
                  style="background-color: white"
                  v-model="product.quantity"
                  vs-size="medium"
                />

                <vs-button
                  v-if="product.quantity > 1"
                  class="p-0"
                  radius
                  color="#FFFFFF"
                  @click="
                    selectedProduct = product;
                    removeQuantityProductCart();
                  "
                >
                  <vs-icon
                    icon="remove_circle"
                    color="dark"
                    size="small"
                  ></vs-icon>
                </vs-button>

                <vs-button
                  class="p-0"
                  v-else
                  radius
                  color="#FFFFFF"
                  @click="(removeConfirm = true), (selectedProduct = product)"
                >
                  <vs-icon icon="delete" color="dark" size="small"></vs-icon>
                </vs-button>
              </div>

              <div class="col-span-1 text-center" style="align-self: center">
                <vs-button
                  v-if="product.quantity != 1"
                  radius
                  color="#FFFFFF"
                  @click="(removeConfirm = true), (selectedProduct = product)"
                >
                  <vs-icon icon="delete" color="dark" size="small"></vs-icon>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="col-span-3 ml-4 mt-4">
        <div class="summary p-4">
          <div class="flex justify-between">
            <div>Summary</div>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between">
            <div>1$</div>
            <div>9₺</div>
          </div>
          <div class="flex justify-between">
            <div>Total Price</div>
            <div>
              {{ getTotalPrice }}
            </div>
          </div>
          <div class="flex justify-between">
            <div>Total Product Unit</div>
            <div>
              {{ getTotalProductUnit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      removeConfirm: false,
      selectedProduct: null,
    };
  },
  methods: {
    removeItemFromCart() {
      this.$store.dispatch("removeProductCart", this.selectedProduct);
    },
    addQuantityProductCart() {
      this.$store.dispatch("addQuantityProductCart", this.selectedProduct);
    },
    removeQuantityProductCart() {
      this.$store.dispatch("removeQuantityProductCart", this.selectedProduct);
    },
  },
  watch: {
    getProducts() {
      if (this.getProducts.length == 0) {
        this.$router
          .push({
            name: "products",
          })
          .catch((error) => {
            if (error.name != "NavigationDuplicated") {
              throw error;
            }
          });
      }
    },
  },
  computed: {
    getProducts() {
      return this.$store.getters.getProductCart;
    },
    getTotalPrice() {
      var total = 0;

      if (this.$store.getters.getProductCart.length) {
        this.$store.getters.getProductCart.forEach((element) => {
          total += element.price * element.quantity;
        });
      }

      return total;
    },
    getTotalProductUnit() {
      var total = 0;

      if (this.$store.getters.getProductCart.length) {
        this.$store.getters.getProductCart.forEach((element) => {
          total += element.quantity;
        });
      }

      return total;
    },
  },
};
</script>

<style scoped>
.header {
  background-color: white;
  width: 100%;
  border-radius: 5px;
  padding: 5px;

  font-size: 1.1rem;
  font-weight: 600;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.productRow {
  padding: 10px;
  background-color: white;
  border-radius: 5px;

  font-size: 1.1rem;
  font-weight: 600;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.summary {
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;

  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}

.cartImage {
  width: 100%;
  border-radius: 10px;
}
.productTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3a3a3a;
}
.productPrice {
  font-size: 1.2rem;
  font-weight: 700;
  color: #3a3a3a;
}
</style>

<style lang="scss">
.vuesax-app-is-ltr .vs-input-number button.vs-input-number--button-plus {
  display: none;
}
.vuesax-app-is-ltr .vs-input-number button.vs-input-number--button-less {
  display: none;
}
.vs-input-number--input {
  font-size: 1.2rem;
  font-weight: 700;
}
.productKg {
  width: 80px;
  padding: 2px;
  border-radius: 5px;
  color: white;
  text-align: center;
}
</style>
